<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <!-- <b-link class="brand-logo">
      <h2 class="brand-text text-primary ml-1">
        Cloud360s
      </h2>
    </b-link> -->

  <b-container>
    <b-row class="align-items-center justify-content-center">
      <b-col cols="12" md="4" class="mb-2 mb-md-0">
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </b-col>

      <b-col cols="12" md="5" class="pl-lg-5">
        <div class="">
          <h2 class="mb-1 h1">
          Page Not Found
        </h2>
        <p class="mb-2 lead">
          Oops! 😖 The requested URL was not found on this server.
        </p>

        <b-button
          variant="primary"
          class="btn-sm-block"
          :to="{path:'/'}"
        >
          Back to home
        </b-button>
        </div>
      </b-col>
    </b-row>

    <!-- <div class="misc-inner p-2 p-sm-3">
  </div> -->
  </b-container>

  </div>

<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BContainer, BRow, BCol, BLink, BButton, BImg } from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.misc-wrapper{
  min-height: 70vh;
}
</style>
